import logo from "./logo.svg";
import "./App.css";
import { useEffect } from "react";
import ContactForm from "./ContactForm";

function App() {
  // You can skip useEffect if you're not using TailwindCSS
  // Otherwise, for the production usage refer to https://tailwindcss.com/docs/installation
  useEffect(() => {
    if (document) {
      const stylesheet = document.createElement("link");
      stylesheet.rel = "stylesheet";
      stylesheet.href = "https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css";

      document.head.appendChild(stylesheet);
    }
  }, []);

  return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Get early access to Pumpd
          </p>
          <div className="text-sm">
            Track your indoor climbing and bouldering progress, find climbing partners and share route
            info. <br/>
            Your feedback helps us create the climbing app you want.
          </div>
          <div className="py-6">
            <ContactForm />
          </div>
        </header>
        <footer className="App-footer">
          <a href="https://aemail.com/xGWP">Interested in working with us?</a>
        </footer>
      </div>
  );
}

export default App;